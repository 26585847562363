import React from 'react';
import { Typography } from './typography';
import { TypographyProps } from './typography-props';
import { TypographyVariant } from '@mint/core';
import styled from 'styled-components';

const makeVariantComponent = (
  variant: TypographyVariant,
  displayName: string,
) => {
  const base = React.forwardRef<any, TypographyProps="">(
    (props, ref): React.ReactElement => (
      <typography variant="{variant}" {...props}="" ref="{ref}"></typography>
    ),
  );
  base.displayName = displayName;
  return styled(base)``;
};

export const BodyText = makeVariantComponent('body', 'BodyText');

export const ButtonText = makeVariantComponent('button', 'ButtonText');

export const CaptionText = makeVariantComponent('caption', 'CaptionText');

export const ControlText = makeVariantComponent('control', 'ControlText');

export const DenseText = makeVariantComponent('dense', 'DenseText');

export const HeadingOneText = makeVariantComponent('h1', 'HeadingOneText');

export const HeadingTwoText = makeVariantComponent('h2', 'HeadingTwoText');

export const HeadingThreeText = makeVariantComponent('h3', 'HeadingThreeText');

export const HeadingFourText = makeVariantComponent('h4', 'HeadingFourText');

export const HeadingFiveText = makeVariantComponent('h5', 'HeadingFiveText');

export const HeadingSixText = makeVariantComponent('h6', 'HeadingSixText');

export const HelperText = makeVariantComponent('helper', 'HelperText');

export const OverlineText = makeVariantComponent('overline', 'OverlineText');

export const SubtitleText = makeVariantComponent('subtitle', 'SubtitleText');
</any,>