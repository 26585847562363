/**
 * Función de utilidad para aplicar nombres de clase.
 * @param input Cadena(s) de nombre(s) de clase, matrices o mapas.
 */
export const className = (
  ...entrada: Matriz<string |="" string[]="" boolean="" null="" undefined="">
): string =>
  input
    .reduce((curr: string[], val) => {
      if (Array.isArray(val)) {
        val.forEach(v => curr.push(v));
      } else if (typeof val === 'string') {
        curr.push(val.trim());
      }
      return curr;
    }, [])
    .join(' ');
</string>