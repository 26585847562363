// This simplified polyfill attempts to follow the ECMAScript Observable proposal.
// See https://github.com/zenparsing/es-observable
import { Observable as LinkObservable } from 'apollo-link';

export type Subscription = ZenObservable.Subscription;
export type Observer<t> = ZenObservable.Observer<t>;

import $$observable from 'symbol-observable';

// rxjs interopt
export class Observable<t> extends LinkObservable<t> {
  public [$$observable]() {
    return this;
  }

  public ['@@observable' as any]() {
    return this;
  }
}
</t></t></t></t>