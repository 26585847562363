import React, { useCallback, useEffect, useState } from 'react';

export interface UseValueOptions<t> {
  onChange?: React.ChangeEventHandler<t>;
  value?: string;
}

export const useValue = <
  T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>({
  onChange,
  value: valueProp = '',
}: UseValueOptions<t>): [string, React.ChangeEventHandler<t>] => {
  const [value, setValue] = useState(valueProp);
  const handleChange = useCallback(
    (e: React.ChangeEvent<t>): void => {
      setValue(e.currentTarget.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );
  useEffect((): void => {
    if (valueProp !== value) {
      setValue(valueProp);
    }
  }, [valueProp]);
  return [value, handleChange];
};
</t></t></t></t></t>