/**
 * Añade las propiedades de uno o más objetos de origen a un objeto de destino. Funciona exactamente igual que
 * `Object.assign`, pero como una utilidad para mantener el soporte para IE 11.
 *
 * @ver https://github.com/apollostack/apollo-client/pull/1009
 */
exportar función assign<a, B="">(a: A, b: B): A & B;
función de exportación asignar<a, B,="" C="">(a: A, b: B, c: C): A & B & C;
función de exportación asignar<a, B,="" C,="" D="">(a: A, b: B, c: C, d: D): A & B & C & D;
función de exportación asignar<a, B,="" C,="" D,="" E="">(
  a: A,
  b: B,
  c: C,
  d: D,
  e: E,
): A & B & C & D & E;
exportar función assign(target: any, ...sources: Matriz<any>): any;
export function assign(
  target: { [key: string]: any },
  ...sources: Array<{ [key: string]: any }>
): { [key: string]: any } {
  sources.forEach(source => {
    if (typeof source === 'undefined' || source === null) {
      return;
    }
    Object.keys(source).forEach(key => {
      target[key] = source[key];
    });
  });
  return target;
}
</any></a,></a,></a,></a,>