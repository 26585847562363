import { DocumentNode } from 'graphql';
import { getFragmentQueryDocument } from 'apollo-utilities';

import { DataProxy, Cache } from './types';
import { justTypenameQuery, queryFromPojo, fragmentFromPojo } from './utils';

export type Transaction<t> = (c: ApolloCache<t>) => void;

exportar clase abstracta ApolloCache<tserialized> implementa DataProxy {
  // necesario para implementar
  // API principal
  public abstract leer<t, TVariables="any">(
    consulta: Cache.ReadOptions<tvariables>,
  ): T | null;
  public abstract escribir<tresult =="" any,="" TVariables="any">(
    escribir: Cache.WriteOptions<tresult, TVariables="">,
  ): void;
  public abstract diff<t>(consulta: Cache.DiffOptions): Cache.DiffResult<t>;
  public abstracto watch(watch: Cache.WatchOptions): () => void;
  public abstracto desalojar<tvariables =="" any="">(
    consulta: Cache.EvictOptions<tvariables>,
  ): Cache.EvictionResult;
  public abstracto reset(): Promesa<void>;

  // intializer / offline / ssr API
  /**
   * Reemplaza el estado existente en la caché (si existe) con los valores expresados por
   * `serializedState`.
   *
   * Llamado cuando se hidrata una caché (renderizado del lado del servidor, o almacenamiento offline),
   * y también (potencialmente) durante recargas en caliente.
   */
  public abstract restaurar(
    serializedState: TSerialized,
  ): ApolloCache<tserialized>;

  /**
   * Expone el estado completo de la caché, en un formato serializable para su posterior restauración.
   */
  public abstract extraer(optimista?: booleano): TSerializado;

  // API optimista
  public abstract extraerOptimista(id: cadena): void;

  // API transaccional
  public abstracto performTransaction(
    transacción: Transacción<tserialized>,
  ): void;
  public abstracto recordOptimisticTransaction(
    transacción: Transacción<tserialized>,
    id: string,
  ): void;

  // optional API
  public transformDocument(document: DocumentNode): DocumentNode {
    return document;
  }
  // experimental
  public transformForLink(document: DocumentNode): DocumentNode {
    return document;
  }

  // DataProxy API
  /**
   *
   * @param options
   * @param optimistic
   */
  public readQuery<querytype, TVariables="any">(
    opciones: DataProxy.Query<tvariables>,
    optimistic: boolean = false,
  ): QueryType | null {
    return this.read({
      query: options.query,
      variables: options.variables,
      optimistic,
    });
  }

  public readFragment<fragmenttype, TVariables="any">(
    opciones: DataProxy.Fragment<tvariables>,
    optimistic: boolean = false,
  ): FragmentType | null {
    return this.read({
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
      variables: options.variables,
      rootId: options.id,
      optimistic,
    });
  }

  public writeQuery<tdata =="" any,="" TVariables="any">(
    opciones: Cache.WriteQueryOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: 'ROOT_QUERY',
      result: options.data,
      query: options.query,
      variables: options.variables,
    });
  }

  public writeFragment<tdata =="" any,="" TVariables="any">(
    opciones: Cache.WriteFragmentOptions<tdata, TVariables="">,
  ): void {
    this.write({
      dataId: options.id,
      result: options.data,
      variables: options.variables,
      query: getFragmentQueryDocument(options.fragment, options.fragmentName),
    });
  }

  public writeData<tdata =="" any="">({
    id,
    data,
  }: Cache.WriteDataOptions<tdata>): void {
    if (typeof id !== 'undefined') {
      let typenameResult = null;
      // Since we can't use fragments without having a typename in the store,
      // we need to make sure we have one.
      // To avoid overwriting an existing typename, we need to read it out first
      // and generate a fake one if none exists.
      try {
        typenameResult = this.read<any>({
          rootId: id,
          optimistic: false,
          query: justTypenameQuery,
        });
      } catch (e) {
        // Do nothing, since an error just means no typename exists
      }

      // tslint:disable-next-line
      const __typename =
        (typenameResult && typenameResult.__typename) || '__ClientData';

      // Add a type here to satisfy the inmemory cache
      const dataToWrite = Object.assign({ __typename }, data);

      this.writeFragment({
        id,
        fragment: fragmentFromPojo(dataToWrite, __typename),
        data: dataToWrite,
      });
    } else {
      this.writeQuery({ query: queryFromPojo(data), data });
    }
  }
}
</any></tdata></tdata></tdata,></tdata></tdata,></tdata></tvariables></fragmenttype,></tvariables></querytype,></tserialized></tserialized></tserialized></void></tvariables></tvariables></t></t></tresult,></tresult></tvariables></t,></tserialized></t></t>